.coming-soon {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
    overflow: hidden;
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    padding: 2rem;
}

h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(45deg, #a9c9ff, #ffbbec);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin: 3rem 0;
}

.feature-item {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 1.5rem;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.feature-item:hover {
    transform: translateY(-5px);
}

.feature-item h3 {
    color: #a9c9ff;
    margin-bottom: 1rem;
}

.cta {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

button {
    padding: 1rem 2rem;
    border-radius: 30px;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

button:hover {
    transform: scale(1.05);
}

.primary-btn {
    background: linear-gradient(45deg, #a9c9ff, #ffbbec);
    color: #000;
}

.secondary-btn {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    backdrop-filter: blur(10px);
}

@media (max-width: 768px) {
    h1 {
        font-size: 2.5rem;
    }

    .features {
        grid-template-columns: 1fr;
    }

    .cta {
        flex-direction: column;
    }
}

.coming-soon-badge {
    background: linear-gradient(45deg, #0369a1, #0c4a6e);
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 25px;
    font-weight: bold;
    display: inline-block;
    margin: 1rem 0;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}