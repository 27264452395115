body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
  background: linear-gradient(135deg, #0f2027, #203a43, #2c5364);
  color: #fff;
  overflow: hidden;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
